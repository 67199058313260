<template>
    <div id="app">
        <!-- <nav>
          <router-link to="/">Home</router-link> |
          <router-link to="/about">About</router-link>
        </nav> -->
        <headerNav></headerNav>
        <navMenu></navMenu>
        <router-view/>
        <footerMenu></footerMenu>
        <goTop></goTop>
        <div class="home-back"></div>
    </div>
</template>
<script>
import headerNav from "@/components/headerNav/index"
import navMenu from '@/components/navMenu'
import footerMenu from '@/components/footer'
import goTop from "@/components/goTop/"

export default {
    components: {
        headerNav,
        navMenu,
        footerMenu,
        goTop
    }
}
</script>
<style lang="less">
@media (min-width: 768px) {
    #app {
        width: 1200px;
        margin: 0 auto;
        padding-top: 64px;
    }
}

@media (max-width: 768px) {
    #app {
        padding-top: 64px;
    }
}

* {
    padding: 0;
    margin: 0;
}

#app {
}
.home-back{
    z-index: -999999;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: linear-gradient(180deg, #D3A9FF 0%, #5062F2 100%);
}
</style>
