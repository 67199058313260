<template>
    <div>
        <div class="info-top" v-if="isPlay">
            <div class="b-b-card noselect">
                <img class="logo" :src="gameInfo.logo" alt="">
                <div class="play">
                    <img class="pointer" src="@/assets/index/play.png" alt="" @click="isPlay=false">
                </div>
            </div>
        </div>
        <div class="gamewin" v-else>
            <iframe
                style="width:100%"
                :src="baseUrl+gameInfo.url"
                frameborder="0"></iframe>
        </div>
        <div class="info-txt noselect">
            <div class="title">{{ gameInfo.title }}:</div>
            <div class="cont warp-6">
                {{ gameInfo.synopsis }}
            </div>
        </div>
        <div class="rec-cont">
            <div class="body-b">
                <div class="body-h">
                    <div class="noselect">More Games</div>
                </div>
                <div class="cont">
                    <div
                        class="b-b-card pointer"
                        v-for="items in gameList"
                        :key="items.id"
                        @click="goDetails(items)"
                    >
                        <img :src="items.logo" alt="">
                        <div class="title no-warp">{{items.title}}</div>
                    </div>
                    <span v-for="item in spanNum" :key="item"></span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { isMobile, scrollToTop } from "@/utils/api"
import { getGameById } from "@/api/games"

    export default {
        data() {
            return {
                value:4,
                gameList:[],
                gameInfo:{},
                spanNum:0,
                isPlay:true,
                baseUrl:''
            }
        },
        mounted() {
            let id = this.$route.query.id

            this.getGameInfo(id);

            if(isMobile()){
                this.spanNum = 3-2
            }else{
                this.spanNum = 10-2
            }

            // this.baseUrl = process.env.VUE_APP_BASE_GAME
            this.baseUrl = 'https://'+window.location.hostname

            scrollToTop(0);
        },
        methods: {
            async getGameInfo(id){
                let { game,similarGames } = await getGameById({id})
                this.gameInfo = game;
                this.gameList = similarGames;
            },
            goDetails(item){
                window.location.href = `${window.location.origin}/details?id=${item.id}`
            },
        },
    }
</script>

<style lang="less" scoped>
.info-top{
    padding-top: 20px;
    box-sizing: border-box;
    .b-b-card{
        margin: 0 auto 21px;
        width: 329px;
        height: 329px;
        border-radius: 20px;
        overflow: hidden;
        position: relative;
        .logo{
            width: 100%;
            height: 100%;
        }
        .play{
            position: absolute;
            top: 0;
            background: rgba(0,0,0,0.1);
            width: 100%;
            height: 100%;
            img{
                width: 87px;
                height: 87px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
}
.gamewin{
    width: 100%;
    height: calc(100vh - 64px);
    iframe{
        width: 100%;
        height: 100%;
    }
}
.info-txt{
    margin: 17px 20px 29px;
    color: #FFF;
    .title{
        margin-bottom: 11px;
        font-family: Inter, Inter;
        font-weight: bold;
        font-size: 18px;
        line-height: 23px;
        text-align: left;
        font-style: normal;
        text-transform: none;
    }
    .cont{
        font-family: Poppins, Poppins;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        text-align: left;
        font-style: normal;
        text-transform: none;
    }
}
.rec-cont{
    .body-b{
        margin-top: 12px;
        padding: 0px 24px;
        box-sizing: border-box;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .body-h{
            margin: 9px 0;
            box-sizing: border-box;
            display: flex;
            justify-content: space-between;
            height: 28px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 20px;
            color: #FFF;
            line-height: 28px;
            text-align: left;
            font-style: normal;
        }
        .cont{
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            margin-bottom: 22px;
            .b-b-card{
                width: 160px;
                height: 212px;
                border-radius: 12px;
                background: #836BE5;
                margin-bottom: 9px;
                img{
                    width: 160px;
                    height: 160px;
                    border-radius: 12px;
                }
                .title{
                    padding: 7px;
                    height: 30px;
                    font-family: PingFangSC, PingFang SC;
                    font-weight: 400;
                    font-size: 22px;
                    color: #FFFFFF;
                    line-height: 30px;
                    text-align: left;
                    font-style: normal;
                }
            }
            >span{
                width: 160px;
            }
        }
    }
}
.pointer{
    cursor: pointer;
}
.noselect{
    -moz-user-select:none;/*火狐*/

    -webkit-user-select:none;/*webkit浏览器*/

    -ms-user-select:none;/*IE10*/

    -khtml-user-select:none;/*早期浏览器*/

    user-select:none;
}
.no-warp{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.warp-6{
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>