<template>
    <div style="padding: 0 14px;box-sizing: border-box">
        <div class="hot-list">
            <div class="hot-h">
                <div class="title noselect">
                    <img src="@/assets/nclass/Hot.png" alt="">
                    <span>Hot games</span>
                </div>
                <div class="more pointer" @click="goSelect(newList[0].key_name)">MORE</div>
            </div>
            <div class="hot-b">
                <div class="h-b-card pointer" @click="goDetails(item)" v-for="item in newList" :key="item.id">
                    <img :src="item.logo" alt="">
                    <p class="no-warp">{{item.title}}</p>
                </div>
            </div>
        </div>
        <div class="top-class">
            <div
                class="class-icon pointer"
                v-for="item in classList"
                :key="item.name"
                @click="goSelect(item.name)"
            >
                <div>
                    <img :src="item.url" alt="">
                    <span>{{item.name}}</span>
                </div>
            </div>
        </div>
        <adContent></adContent>
        <div class="game-body" v-for="(items ,key) in gameList" :key="key">
            <div class="body-h noselect">
                <div class="title noselect">{{ items[0].key_name }} Games</div>
                <div class="more pointer" @click="goSelect(items[0].key_name)">MORE</div>
            </div>
            <div class="new-b">
                <div
                    class="b-b-card new pointer"
                    v-for="item in items"
                    @click="goDetails(item)"
                    :key="item.id"
                >
                    <img :src="item.logo" alt="">
                    <div class="title no-warp">{{item.title}}</div>
                    <van-rate size="10" color="#FFC900" v-model="item.rating" />
                </div>
            </div>
        </div>
        <div class="game-blurb noselect">
            Welcome to {{blurbUrl}} Here, various free online games can be played directly in your browser without any downloads. Our collection of online games will provide you with hours ofentertainment. Invite your friends over and enjoy these popular games together. From classic arcade games to car games, cricket games, puzzles, mahjong, and more, there's something for everyone in our game categories. We have carefully selected the best online games, and we review and add new games every day, so you can learn new skills as you play! Let's start playing
        </div>
    </div>
</template>

<script>
import {
    getGameList,
    getGame, getClassList
} from "@/api/games";
import adContent from "@/components/adContent/index.vue";
import classList from "@/utils/navMenu";
    export default {
        components: {
            adContent
        },
        data() {
            return {
                gameList: {},
                classList: [],
                newList:[],
                sClass: '',
                carouselList:[],
                blurbUrl: process.env.VUE_APP_BASE_GAME
            }
        },
        mounted() {
            this.getClass();
            this.getList();
        },
        methods: {
            goDetails(item){
                this.$router.push({
                    path:'/details',
                    query:{
                        id:item.id
                    }
                })
            },
            goSelect(key){
                if(key==='All'){
                    this.$router.go(0)
                }
                this.$router.push({
                    path:'/selectGame',
                    query:{
                        id:'more',
                        key
                    }
                })
            },
            async getClass(){
                let list = await getClassList();
                list.forEach((item,i) => {
                    let data = classList.find(items=> item.key_name == items.name);
                    if(data){
                        list.splice(i,1,data);
                    }
                })
                list.unshift({
                    name:'All',
                    url: require('@/assets/nclass/All.png')
                })
                console.log(list)
                this.classList = list;
            },
            async getList(){
                let cList = await getGame({count:6});
                this.carouselList = cList;
                this.newList = await getGame({count:15});
                let games = await getGameList();
                this.gameList = games;
            },
        },
    }
</script>

<style lang="less" scoped>
.pointer{
    cursor: pointer;
}
.noselect{
    -moz-user-select:none;/*火狐*/

    -webkit-user-select:none;/*webkit浏览器*/

    -ms-user-select:none;/*IE10*/

    -khtml-user-select:none;/*早期浏览器*/

    user-select:none;
}
.no-warp{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.hot-list{
    width: 100%;
    height: 172px;
    padding: 8px 10px;
    box-sizing: border-box;
    background: #9276E5;
    border-radius: 19px;
    .hot-h{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .title{
            display: flex;
            align-items: center;
            img{
                width: 34px;
                height: 34px;
            }
            span{
                font-family: PingFangSC, PingFang SC;
                font-weight: 500;
                font-size: 18px;
                color: #FFFFFF;
                text-align: left;
                font-style: normal;
            }
        }
        .more{
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #FFFFFF;
            text-align: left;
            font-style: normal;
            text-transform: uppercase;
        }
    }
    .hot-b{
        overflow: auto;
        white-space: nowrap;
        @media (min-width: 768px){
            &::-webkit-scrollbar{
                height: 7px;
            }
            &::-webkit-scrollbar-thumb{
                border-radius: 10px;
                box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
                background: rgba(0,0,0,0.2);
            }
            &::-webkit-scrollbar-track{
                box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
                border-radius: 0;
                background: rgba(0,0,0,0.1);
            }
        }
        .h-b-card{
            display: inline-block;
            margin-right: 12px;
            img{
                width: 91px;
                height: 91px;
                background: #D8D8D8;
                border-radius: 14px;
            }
            p{
                width: 93px;
                height: 20px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                font-size: 14px;
                color: #FFFFFF;
                line-height: 20px;
                text-align: left;
                font-style: normal;
            }
        }
    }
}
.top-class{
    margin-bottom: 19px;
    padding-top: 22px;
    box-sizing: border-box;
    width: 100%;
    overflow: auto;
    white-space: nowrap;
    @media (min-width: 768px){
        &::-webkit-scrollbar{
            height: 7px;
        }
        &::-webkit-scrollbar-thumb{
            border-radius: 10px;
            box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
            background: rgba(0,0,0,0.2);
        }
        &::-webkit-scrollbar-track{
            box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
            border-radius: 0;
            background: rgba(0,0,0,0.1);
        }
    }
    .class-icon{
        margin-right: 7px;
        display: inline-block;
        background: #836BE5;
        border-radius: 25px;
        padding: 6px 10px;
        box-sizing: border-box;
        div{
            display: flex;
            align-items: center;
            img{
                width: 34px;
                height: 34px;
                margin-right: 5px;
            }
            span{
                font-family: PingFangSC, PingFang SC;
                font-weight: 500;
                font-size: 16px;
                color: #FFFFFF;
                text-align: left;
                font-style: normal;
            }
        }
    }
}
.game-body{
    margin-bottom: 19px;
    .body-h{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .title{
            height: 25px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 18px;
            color: #FFFFFF;
            line-height: 25px;
            text-align: left;
            font-style: normal;
        }
        .more{
            height: 20px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #FFFFFF;
            line-height: 20px;
            text-align: left;
            font-style: normal;
            text-transform: uppercase;
        }
    }
    .new-b{
        margin-top: 12px;
        box-sizing: border-box;
        overflow: auto;
        white-space: nowrap;
        @media (min-width: 768px){
            &::-webkit-scrollbar{
                height: 7px;
            }
            &::-webkit-scrollbar-thumb{
                border-radius: 10px;
                box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
                background: rgba(0,0,0,0.2);
            }
            &::-webkit-scrollbar-track{
                box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
                border-radius: 0;
                background: rgba(0,0,0,0.1);
            }
        }
        .new{
            display: inline-block;
            margin-right: 14px;
        }
        .b-b-card{
            margin-bottom: 15px;
            text-align: center;
            img{
                width: 100px;
                height: 100px;
                border-radius: 15px;
            }
            .title{
                margin-top: 4px;
                width: 100px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                font-size: 14px;
                color: #FFFFFF;
                line-height: 20px;
                font-style: normal;
            }
        }
    }
    .body-b{
        margin-top: 12px;
        padding: 0px 24px;
        box-sizing: border-box;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
}
.game-blurb{
    border-radius: 10px;
    padding: 8px 12px;
    box-sizing: border-box;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 16px;
    color: #F9F9F9;
    line-height: 22px;
    text-align: left;
    font-style: normal;
}
</style>