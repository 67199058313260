<template>
    <div style="padding: 0 15px;box-sizing: border-box">
        <div class="game-body">
            <div class="body-h">
                <div class="noselect">{{ title }}</div>
            </div>
            <div class="cont">
                <div
                    class="b-b-card pointer"
                    v-for="items in gameList"
                    :key="items.id"
                    @click="goDetails(items)"
                >
                    <img :src="items.logo" alt="">
                    <div class="title no-warp">{{items.title}}</div>
                </div>
                <span v-for="item in spanNum" :key="item"></span>
            </div>
        </div>
        <div class="game-body" v-if="showMore">
            <div class="body-h">
                <div class="noselect">more fun games</div>
            </div>
            <div class="cont">
                <div
                    class="b-b-card pointer"
                    v-for="items in moreList"
                    :key="items.id"
                    @click="goDetails(items)"
                >
                    <img :src="items.logo" alt="">
                    <div class="title no-warp">{{items.title}}</div>
                </div>
                <span v-for="item in spanNum" :key="item"></span>
            </div>
        </div>
        <div class="class-info" v-if="!showMore">
            {{ intro }}
        </div>
    </div>
</template>

<script>
import eventBus from '@/utils/eventBus'
import { scrollToTop, isMobile } from '@/utils/api'
import { getGameByKey, searchGame, getGame  } from "@/api/games"
import classInfo from "@/utils/classInfo"

    export default {
        data() {
            return {
                title: '',
                gameList: [],
                moreList: [],
                spanNum:0,
                showMore: false,
                intro: ''
            }
        },
        mounted() {
            if(isMobile()){
                this.spanNum = 3-2;
            }else{
                this.spanNum = 11-2;
            }

            scrollToTop(0);
            eventBus.$on('searchgame',name=>{
                let {id,key} = name;
                this.getGameList(id,key)
            })
            let {id,key} = this.$route.query;
            this.getGameList(id,key);
        },
        methods: {
            goDetails(item){
                this.$router.push({
                    path:'/details',
                    query:{
                        id:item.id
                    }
                })
            },
            async getGameList(id,key){
                let games = [];
                let title = '';
                if(id=='search'){
                    games = await searchGame({q:key});
                    title = `search found "${key}"`;
                    this.moreList = await getGame({count:20});
                    this.showMore = true;
                }else if(id=='more'){
                    this.intro = classInfo.find(item => item.name === key).intro;
                    if(key === 'All'){
                        games = await getGame();
                    }else{
                        games = await getGameByKey({category:key});
                    }
                    title = key + ' games';
                    this.showMore = false;
                }else{
                    console.log(key);
                }
                this.gameList = games;
                this.title = title;
            }
        },
    }
</script>

<style lang="less" scoped>
.pointer{
    cursor: pointer;
}
.noselect{
    -moz-user-select:none;/*火狐*/

    -webkit-user-select:none;/*webkit浏览器*/

    -ms-user-select:none;/*IE10*/

    -khtml-user-select:none;/*早期浏览器*/

    user-select:none;
}
.no-warp{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.body-h{
    margin: 9px 0;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    height: 28px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 20px;
    color: #FFF;
    line-height: 28px;
    text-align: left;
    font-style: normal;
}
.cont{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 22px;
    .b-b-card{
        width: 160px;
        height: 212px;
        border-radius: 12px;
        background: #836BE5;
        margin-bottom: 9px;
        img{
            width: 160px;
            height: 160px;
            border-radius: 12px;
        }
        .title{
            padding: 7px;
            height: 30px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 22px;
            color: #FFFFFF;
            line-height: 30px;
            text-align: left;
            font-style: normal;
        }
    }
    >span{
        width: 160px;
    }
}
.class-info{
    background: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    color: #FFF;
    padding: 11px;
    box-sizing: border-box;
}
</style>