<template>
    <div>
        <div class="header">
            <img class="logo pointer" src="@/assets/index/logo.png" alt="" @click="goIndex">
            <div class="search">
                <input
                    type="text"
                    v-model="searchText"
                    placeholder="keywords"
                >
                <img
                    class="pointer"
                    src="@/assets/index/search.png"
                    alt=""
                    @click="searchGame"
                >
            </div>
        </div>
    </div>
</template>

<script>
import eventBus from "@/utils/eventBus";
    export default {
        data() {
            return {
                showInput: false,
                searchText: '',
            }
        },
        mounted() {
        },
        methods: {
            searchGame(){
                let obj = {
                    id: 'search',
                    key: this.searchText
                }
                if(this.$route.name !== 'selectGame'){
                    this.$router.push({
                        path: 'selectGame',
                        query: obj
                    })
                }else{
                    eventBus.$emit('searchgame',obj)
                }
            },
            goIndex(){
                if(this.$route.path !== '/'){
                    this.$router.push('/');
                }else{
                    this.$router.go(0)
                }
            }
        },
    }
</script>

<style lang="less" scoped>
@media (min-width: 768px) {
  .header{
    width: 1200px;
  }
}
@media (max-width: 768px) {
  .header{
    width: 100%;
  }
}
.pointer{
    cursor: pointer;
}
.header{
    background: #D3A9FF;
    padding: 0 8px;
    box-sizing: border-box;
    display: flex;
    height: 64px;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    z-index: 999;
    .logo{
        //width: 157px;
        height: 27px;
    }
    .search{
        width: 231px;
        height: 38px;
        background: #FFFFFF;
        border-radius: 10px;
        padding:0 15px 0 12px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        input{
            &::placeholder{
                color: #AAADBE;
            }
            background: #FFF;
            color: #000;
            width: 170px;
            height: 35px;
            border: none;
            outline: none;
        }
        img{
            width: 17px;
            height: 17px;
        }
    }
}
</style>